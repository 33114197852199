import axios from "axios";
import {
  getIncludedTaxonomyTerms,
  getIncludedImages,
  getIncludedFiles,
  BASE_URL,
  CONSUMER_UUID
} from "./helpers";
import { IProject } from "../types";

const FIELDS = [
  "nid",
  "changed",
  "title",
  "field_category",
  "field_client",
  "field_description",
  "field_files",
  "field_image_gallery",
  "field_location",
  "field_main_image",
  "field_subtitle",
  "field_year"
];
const INCLUDES = ["field_category", "field_files", "field_main_image"];

export function getProject(uuid: string): Promise<IProject> {
  const params = {
    consumerId: CONSUMER_UUID,
    "fields[node--project]": FIELDS.join(","),
    include: INCLUDES.join(","),
    sort: "-field_publication_date"
  };

  return axios
    .get(`${BASE_URL}/node/project/${uuid}`, {
      params
    })
    .then(response => {
      if (response.status >= 400) {
        throw Error("Not found");
      }
      return response;
    })
    .then(({ data: payload }) => {
      return convertJsonApiPayload(payload.data, payload);
    });
}

export function getProjects(
  category?: number,
  limit?: number
): Promise<IProject[]> {
  const params: any = {
    consumerId: CONSUMER_UUID,
    "fields[node--project]": FIELDS.join(","),
    include: INCLUDES.join(","),
    sort: "-field_publication_date"
  };

  if (category) {
    params["filter[field_category.tid]"] = category;
  }

  if (limit !== undefined) {
    params["page[limit]"] = limit;
  }

  return axios
    .get(`${BASE_URL}/node/project`, {
      params
    })
    .then(({ data: payload }) => {
      return payload.data.map((item: any) => {
        return convertJsonApiPayload(item, payload);
      });
    });
}

function convertJsonApiPayload(item: any, payload: any): IProject {
  return {
    category: getIncludedTaxonomyTerms(payload, item, "field_category")[0],
    client: item.attributes.field_client,
    date: new Date(item.attributes.changed),
    description: item.attributes.field_description.processed,
    files: getIncludedFiles(payload, item, "field_files"),
    gallery:
      item.relationships &&
      item.relationships.field_image_gallery &&
      item.relationships.field_image_gallery.data &&
      item.relationships.field_image_gallery.data.length
        ? item.relationships.field_image_gallery.data.map(
            ({ id, type }: { id: string; type: string }) => ({ id, type })
          )
        : [],
    id: item.id,
    location: item.attributes.field_location,
    mainImage: getIncludedImages(payload, item, "field_main_image", true)[0],
    year: item.attributes.field_year,
    subtitle: item.attributes.field_subtitle,
    teaser:
      item.attributes.field_description.summary !== undefined &&
      (item.attributes.field_description.summary as string).length > 0
        ? item.attributes.field_description.summary
        : item.attributes.field_description.processed,
    title: item.attributes.title
  };
}
