import React from "react";
import Loader from "./Loader";
import Categories from "./Categories";
import Copyright from "./Copyright";
import Gallery from "./Gallery";
import ResponsiveImage from "./ResponsiveImage";
import { IProject, ITaxonomyTerm } from "../types";
import { getProject } from "../api/projects";
import icon from "./file-icon.png";
import "./Project.css";

interface Props {
  id: string;
  onCategoryClick: (term?: ITaxonomyTerm) => void;
}

interface State {
  loading?: boolean;
  notFound?: boolean;
  project?: IProject;
}

export default class Project extends React.Component<Props, State> {
  state: State = {};

  componentDidMount() {
    this.fetchProject();
  }

  componentWillReceiveProps(nextProps: Props) {
    const { id } = this.props;
    const { id: newId } = nextProps;
    if (id !== newId) {
      this.fetchProject();
    }
  }

  fetchProject = () => {
    const { id } = this.props;

    this.setState({ loading: true });
    getProject(id).then(
      project => {
        this.setState({ loading: false, project });
      },
      () => {
        this.setState({ loading: false, notFound: true });
      }
    );
  };

  render() {
    const { loading, notFound, project } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (notFound) {
      return <ProjectNotFound />;
    }

    if (!project) {
      return null;
    }
    return (
      <article className="project">
        <header className="project__header">
          <ResponsiveImage image={project.mainImage} />
        </header>
        <div className="project__content container">
          <div className="project__meta-information">
            <h2 className="project__title">{project.title}</h2>
            {project.subtitle && (
              <h3 className="project__subtitle">{project.subtitle}</h3>
            )}
            <div className="project__location">{project.location}</div>
            <div className="project__year">{project.year}</div>
            {project.files && project.files.length > 0 && (
              <ul className="project__files">
                {project.files.map(file => (
                  <li key={file.id}>
                    <a href={file.path} title="Télécharger le fichier">
                      <img alt="" src={icon} />
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div
            className="project__description"
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
        </div>
        {project.gallery && (
          <div className="project__gallery container">
            <Gallery lines={project.gallery} />
          </div>
        )}
        <footer className="project__other-projects container">
          <h3 className="page-title">
            <span className="page-title__first-line">Tous nos</span>
            <span className="page-title__second-line">Projets</span>
          </h3>
          <Categories
            activeCategory={project.category}
            onCategoryClick={this.props.onCategoryClick}
          />
        </footer>
        <Copyright />
      </article>
    );
  }
}

function ProjectNotFound() {
  return (
    <article className="project project--not-found">Project not found</article>
  );
}
