import axios from "axios";
import { Dict, IImage } from "../types";
import { BASE_URL, CONSUMER_UUID, getIncludedImages } from "./helpers";

const FIELDS: Dict<string[]> = {
  "paragraph--image_3x1": ["field_image"],
  "paragraph--image_3x2": ["field_image_3x2"],
  "paragraph--news_2x2": ["field_image_news_2x2"],
  "paragraph--news_2x3": ["field_image_news_2x3"],
  "paragraph--news_3x2": ["field_image_news_3x2"],
  "paragraph--images_1x1_1x1_1x1": [
    "field_image_1x1a",
    "field_image_1x1b",
    "field_image_1x1c"
  ],
  "paragraph--image_1x1_2x1": ["field_image_1x1a", "field_image_2x1"],
  "paragraph--image_2x1_1x1": ["field_image_1x1a", "field_image_2x1"],
  "paragraph--images_1x2_2x2": ["field_image_1x2", "field_image_2x2"],
  "paragraph--image_2x2_1x2": ["field_image_1x2", "field_image_2x2"]
};

export function getGalleryLine(
  type: string,
  uuid: string
): Promise<Dict<IImage>> {
  if (FIELDS[type] === undefined) {
    return Promise.reject(
      `Type "${type}" is unkown! Cannot retrieve gallery line!`
    );
  }

  const params: any = {
    consumerId: CONSUMER_UUID,
    [`fields[${type}]`]: FIELDS[type].join(","),
    include: FIELDS[type].join(",")
  };

  return axios
    .get(`${BASE_URL}/paragraph/${type.replace(/^paragraph--/, "")}/${uuid}`, {
      params
    })
    .then(r => convertJsonApiPayload(r.data, FIELDS[type]));
}

function convertJsonApiPayload(payload: any, fields: string[]): Dict<IImage> {
  const images: Dict<IImage> = {};
  const item = payload.data;

  if (item === undefined) {
    return {};
  }

  fields.forEach(field => {
    images[field] = getIncludedImages(payload, item, field, true)[0];
  });

  return images;
}
