import React from "react";
import SmoothScroll from "smooth-scroll";
import { getHomeContent } from "./api/home-page";
import "./App.css";
import Contact from "./components/Contact";
import Menu from "./components/Menu";
import News from "./components/News";
import Philosophy from "./components/Philosophy";
import Project from "./components/Project";
import Projects from "./components/Projects";
import ResponsiveImage from "./components/ResponsiveImage";
import Team from "./components/Team";
import { IHomePage, ITaxonomyTerm } from "./types";

interface State {
  activeCategory?: ITaxonomyTerm;
  activeProject?: string;
  animateClose: boolean;
  content?: IHomePage;
  lastScrollPos?: number;
  loading?: boolean;
}

class App extends React.Component<{}, State> {
  diaporama: React.RefObject<HTMLDivElement> = React.createRef();
  timeout?: NodeJS.Timeout;
  scroll = new SmoothScroll();
  state: State = { animateClose: false };

  componentDidMount() {
    this.setState({ loading: true });
    getHomeContent().then(content => {
      this.setState({ content, loading: false });
    });
    window.addEventListener("popstate", this.handlePathChange);
    this.handlePathChange();
    setTimeout(this.animateDiaporama, 5000);
  }

  animateDiaporama = () => {
    if (this.diaporama.current) {
      const node = this.diaporama.current;
      const className = "site-header__diaporama-image";
      const classNameShow = `${className}--show`;
      let switchNext = false;
      node.querySelectorAll(`.${className}`).forEach(image => {
        if (image.classList.contains(classNameShow)) {
          image.classList.remove(classNameShow);
          switchNext = true;
        } else if (switchNext) {
          image.classList.add(classNameShow);
          switchNext = false;
        }
      });

      if (node.querySelectorAll(`.${classNameShow}`).length === 0) {
        const firstImage = node.querySelector(`.${className}`);
        if (firstImage) {
          firstImage.classList.add(classNameShow);
        }
      }
    }
    setTimeout(this.animateDiaporama, 5000);
  };

  pushState = (uuid?: string) => {
    window.history.pushState(
      { isHome: !Boolean(uuid), project: uuid },
      "",
      uuid ? `/project/${uuid}` : "/"
    );
  };

  handlePathChange = (e?: PopStateEvent) => {
    if (e === undefined || !e.state) {
      const matches = window.location.pathname.match(/^\/project\/([\w-]+)/);
      if (matches && matches[1]) {
        this.setState({ activeProject: matches[1], animateClose: true });
      } else if (this.state.activeProject) {
        this.handleCloseModal();
      }
      return;
    }

    if (e.state.isHome) {
      if (this.state.activeProject) {
        this.pushState();
        this.handleCloseModal();
      }
    } else {
      this.setState({ activeProject: e.state.project, animateClose: true });
    }
  };

  handleModalScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const { animateClose, lastScrollPos } = this.state;
    const scrollPos = e.currentTarget.scrollTop;
    const node = document.getElementById("modal__close");
    if (animateClose && node) {
      if (!lastScrollPos || scrollPos > lastScrollPos) {
        node.classList.add("modal__close--hide");
      } else if (lastScrollPos && scrollPos < lastScrollPos) {
        node.classList.remove("modal__close--hide");
      }
      this.setState({ lastScrollPos: scrollPos });

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        if (node) {
          node.classList.add("modal__close--hide");
        }
      }, 3000);
    }
  };

  handleCategoryClick = (term?: ITaxonomyTerm) => {
    this.setState({ activeCategory: term }, () => {
      if (this.state.activeProject) {
        this.handleCloseModal();
        this.scrollTo("categories-scroll-target");
      }
    });
  };

  handleProjectClick = (uuid: string) => {
    this.setState({ activeProject: uuid, animateClose: true });
    this.pushState(uuid);
  };

  handleCloseModal = () => {
    const modal = document.getElementById("modal");
    const close = document.getElementById("modal__close");
    if (modal) {
      this.pushState();
      modal.classList.add("close");
      if (close) {
        close.classList.add("modal__close--hide-immediate");
      }
      setTimeout(() => {
        this.setState({ activeProject: undefined, animateClose: false });
      }, 200);
    }
  };

  handleMenuContactClick = () => {
    this.scrollTo("contact-scroll-target");
  };

  handleMenuNewsClick = () => {
    this.scrollTo("news-scroll-target");
  };

  handleMenuPhilosophyClick = () => {
    this.scrollTo("philosophy-scroll-target");
  };

  handleMenuProjectsClick = () => {
    this.scrollTo("categories-scroll-target");
  };

  handleMenuTeamClick = () => {
    this.scrollTo("team-scroll-target");
  };

  handleScrollDown = () => {
    this.scrollTo("initial-scroll-target", { offset: -20 });
  };

  scrollTo = (id: string, overrides = {}) => {
    this.scroll.animateScroll(document.getElementById(id), null, {
      offset: 100,
      updateURL: false,
      ...overrides
    });
  };

  render() {
    const { activeCategory, activeProject, content, loading } = this.state;

    return (
      <main id="site-content">
        <header id="site-header">
          {content && content.diaporama.length > 0 && (
            <div className="site-header__diaporama" ref={this.diaporama}>
              {content.diaporama.map((image, i) => (
                <ResponsiveImage
                  className={`site-header__diaporama-image${
                    i === 0 ? " site-header__diaporama-image--show" : ""
                  }`}
                  image={image}
                  key={i}
                />
              ))}
            </div>
          )}
          <img alt="" id="logo" src="/images/logo.svg" />
          <span id="scroll-down" onClick={this.handleScrollDown} />
        </header>
        <span id="initial-scroll-target" />
        <Menu
          onClickContact={this.handleMenuContactClick}
          onClickNews={this.handleMenuNewsClick}
          onClickPhilosophy={this.handleMenuPhilosophyClick}
          onClickProjects={this.handleMenuProjectsClick}
          onClickTeam={this.handleMenuTeamClick}
        />
        <span id="news-scroll-target" />
        <News />
        <span id="categories-scroll-target" />
        <Projects
          category={activeCategory}
          onProjectClick={this.handleProjectClick}
        />
        <span id="philosophy-scroll-target" />
        <Philosophy content={content} loading={loading} />
        <span id="team-scroll-target" />
        <Team content={content} loading={loading} />
        <span id="contact-scroll-target" />
        <Contact />
        {activeProject && (
          <div className="open" id="modal">
            <div className="modal__wrapper" onScroll={this.handleModalScroll}>
              <span id="modal__close" onClick={this.handleCloseModal} />
              <Project
                id={activeProject}
                onCategoryClick={this.handleCategoryClick}
              />
            </div>
          </div>
        )}
      </main>
    );
  }
}

export default App;
