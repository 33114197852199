import React from "react";
import Loader from "./Loader";
import { IHomePage } from "../types";
import "./Philosophy.css";

export default function Philosophy({
  content,
  loading
}: {
  content?: IHomePage;
  loading?: boolean;
}) {
  return (
    <div className="philosophy">
      <div className="container">
        <div className="philosophy__header">
          <h2 className="page-title">
            <span className="page-title__first-line">Notre</span>
            <span className="page-title__second-line">Philosophie</span>
          </h2>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div
            className="philosophy__content"
            dangerouslySetInnerHTML={{
              __html: content ? content.philosophy : ""
            }}
          />
        )}
      </div>
    </div>
  );
}
