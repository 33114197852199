import React from "react";
import { IImage } from "../types";

interface Props {
  className?: string;
  image: IImage;
}

export default function ResponsiveImage({ className, image }: Props) {
  const { alt, path, derivatives } = image;

  if (!derivatives) {
    return <img alt={alt} src={path} />;
  } else {
    let smallMatch, mediumMatch, largeMatch;
    const widthRegex = /styles\/[\w]+_(\d+)x\d+\//;
    let srcSet: string[] = [];

    if (derivatives.small) {
      smallMatch = derivatives.small.match(widthRegex);
      srcSet.push(
        `${derivatives.small} ${
          smallMatch && smallMatch[1] ? smallMatch[1] : 300
        }w`
      );
    }

    if (derivatives.medium) {
      mediumMatch = derivatives.medium.match(widthRegex);
      srcSet.push(
        `${derivatives.medium} ${
          mediumMatch && mediumMatch[1] ? mediumMatch[1] : 768
        }w`
      );
    }

    if (derivatives.large) {
      largeMatch = derivatives.large.match(widthRegex);
      srcSet.push(
        `${derivatives.large} ${
          largeMatch && largeMatch[1] ? largeMatch[1] : 1280
        }w`
      );
    }

    return (
      <img
        alt={alt}
        className={className}
        src={path}
        srcSet={srcSet.join(",")}
      />
    );
  }
}
