import React from "react";
import { getCategories } from "../api/categories";
import { ITaxonomyTerm } from "../types";
import "./Categories.css";
import Loader from "./Loader";
import TaxonomyTerm from "./TaxonomyTerm";

interface Props {
  activeCategory?: ITaxonomyTerm;
  onCategoryClick: (category?: ITaxonomyTerm) => void;
}

interface State {
  categories?: ITaxonomyTerm[];
  loading?: boolean;
}

export default class Categories extends React.PureComponent<Props, State> {
  state: State = {};

  componentDidMount() {
    this.setState({ loading: true });
    getCategories()
      .then(categories => {
        this.setState({ categories, loading: false });
      })
      .catch(error => console.error(error));
  }

  render() {
    const { activeCategory } = this.props;
    const { categories, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (!categories || categories.length === 0) {
      return null;
    }

    return (
      <ul className="categories">
        <li
          className={`categories__category${
            activeCategory === undefined ? " categories__category--active" : ""
          }`}
          onClick={() => {
            this.props.onCategoryClick();
          }}
        >
          Tous
        </li>
        {categories.map(item => (
          <li
            className={`categories__category${
              activeCategory && activeCategory.id === item.id
                ? " categories__category--active"
                : ""
            }`}
            key={item.tid}
            onClick={() => {
              this.props.onCategoryClick(item);
            }}
          >
            <TaxonomyTerm term={item} />
          </li>
        ))}
      </ul>
    );
  }
}
