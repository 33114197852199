import React from "react";
import "./Copyright.css";

export default function Copyright() {
  return (
    <div className="copyright">
      © 2019 VVR Architectes |{" "}
      <a href="http://crayonoir.ch/" rel="noopener noreferrer" target="_blank">
        by Crayonoir
      </a>
    </div>
  );
}
