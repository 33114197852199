import React from "react";
import { getGalleryLine } from "../api/gallery-lines";
import { Dict, IGallery, IImage } from "../types";
import Loader from "./Loader";
import ResponsiveImage from "./ResponsiveImage";

interface Props {
  line: IGallery;
}
interface State {
  images?: Dict<IImage>;
  loading?: boolean;
}

export default class GalleryLine extends React.PureComponent<Props, State> {
  state: State = {};

  componentDidMount() {
    const { line } = this.props;

    this.setState({ loading: true });
    getGalleryLine(line.type, line.id)
      .then(images => {
        this.setState({ images, loading: false });
      })
      .catch(error => console.error(error));
  }

  render() {
    const { line } = this.props;
    const { images, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (!images) {
      return null;
    }

    switch (line.type) {
      case "paragraph--image_3x1":
        return <GalleryLine3x1Item images={images} />;
      case "paragraph--image_3x2":
        return <GalleryLine3x2Item images={images} />;
      case "paragraph--images_1x1_1x1_1x1":
        return <GalleryLine1x11x11x1Item images={images} />;
      case "paragraph--image_1x1_2x1":
        return <GalleryLine1x12x1Item images={images} />;
      case "paragraph--image_2x1_1x1":
        return <GalleryLine2x11x1Item images={images} />;
      case "paragraph--images_1x2_2x2":
        return <GalleryLine1x22x2Item images={images} />;
      case "paragraph--image_2x2_1x2":
        return <GalleryLine2x21x2Item images={images} />;
      default:
        return null;
    }
  }
}

export function GalleryLine3x1Item({ images }: { images: Dict<IImage> }) {
  const { field_image } = images;
  return (
    <div className="gallery-line gallery-line--3x1">
      <figure>
        <ResponsiveImage image={field_image} />
      </figure>
    </div>
  );
}

export function GalleryLine3x2Item({ images }: { images: Dict<IImage> }) {
  const { field_image_3x2 } = images;
  return (
    <div className="gallery-line gallery-line--3x2">
      <figure>
        <ResponsiveImage image={field_image_3x2} />
      </figure>
    </div>
  );
}

export function GalleryLine1x11x11x1Item({ images }: { images: Dict<IImage> }) {
  const { field_image_1x1a, field_image_1x1b, field_image_1x1c } = images;
  return (
    <div className="gallery-line gallery-line--1x11x11x1">
      <figure>
        <ResponsiveImage image={field_image_1x1a} />
      </figure>
      <figure>
        <ResponsiveImage image={field_image_1x1b} />
      </figure>
      <figure>
        <ResponsiveImage image={field_image_1x1c} />
      </figure>
    </div>
  );
}

export function GalleryLine1x12x1Item({ images }: { images: Dict<IImage> }) {
  const { field_image_1x1a, field_image_2x1 } = images;
  return (
    <div className="gallery-line gallery-line--1x12x1">
      <figure>
        <ResponsiveImage image={field_image_1x1a} />
      </figure>
      <figure>
        <ResponsiveImage image={field_image_2x1} />
      </figure>
    </div>
  );
}

export function GalleryLine2x11x1Item({ images }: { images: Dict<IImage> }) {
  const { field_image_1x1a, field_image_2x1 } = images;
  return (
    <div className="gallery-line gallery-line--2x11x1">
      <figure>
        <ResponsiveImage image={field_image_2x1} />
      </figure>
      <figure>
        <ResponsiveImage image={field_image_1x1a} />
      </figure>
    </div>
  );
}

export function GalleryLine1x22x2Item({ images }: { images: Dict<IImage> }) {
  const { field_image_1x2, field_image_2x2 } = images;
  return (
    <div className="gallery-line gallery-line--1x22x2">
      <figure>
        <ResponsiveImage image={field_image_1x2} />
      </figure>
      <figure>
        <ResponsiveImage image={field_image_2x2} />
      </figure>
    </div>
  );
}

export function GalleryLine2x21x2Item({ images }: { images: Dict<IImage> }) {
  const { field_image_1x2, field_image_2x2 } = images;
  return (
    <div className="gallery-line gallery-line--2x21x2">
      <figure>
        <ResponsiveImage image={field_image_2x2} />
      </figure>
      <figure>
        <ResponsiveImage image={field_image_1x2} />
      </figure>
    </div>
  );
}
