import React from "react";
import { getGalleryLine } from "../api/gallery-lines";
import { IGallery, IImage } from "../types";
import Loader from "./Loader";
import ResponsiveImage from "./ResponsiveImage";

interface Props {
  className?: string;
  image: IGallery;
}

interface State {
  loading: boolean;
  image?: IImage;
}

export default class AsyncResponsiveImage extends React.Component<
  Props,
  State
> {
  state: State = { loading: true };

  componentDidMount() {
    this.fetchImage();
  }

  fetchImage = () => {
    const { image } = this.props;
    getGalleryLine(image.type, image.id).then(images => {
      this.setState({ image: images[Object.keys(images)[0]], loading: false });
    });
  };

  render() {
    const { loading, image } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (!image) {
      return null;
    } else {
      return <ResponsiveImage className={this.props.className} image={image} />;
    }
  }
}
