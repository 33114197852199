import React from "react";
import { ITaxonomyTerm } from "../types";

interface Props {
  term: ITaxonomyTerm;
}

export default function TaxonomyTerm({ term }: Props) {
  return <span className="taxonomy-term">{term.name}</span>;
}
