import axios from "axios";
import { BASE_URL, CONSUMER_UUID } from "./helpers";
import { ITaxonomyTerm } from "../types";

export function getCategories(): Promise<ITaxonomyTerm[]> {
  return axios
    .get(`${BASE_URL}/taxonomy_term/categories`, {
      params: {
        consumerId: CONSUMER_UUID
      }
    })
    .then(r => convertJsonApiPayload(r.data));
}

function convertJsonApiPayload(payload: any): ITaxonomyTerm[] {
  return payload.data.map((item: any) => {
    const term: ITaxonomyTerm = {
      id: item.id,
      name: item.attributes.name,
      tid: item.attributes.drupal_internal__tid,
      type: item.type
    };
    return term;
  });
}
