import React from "react";
import "./Menu.css";

interface Props {
  onClickContact: () => void;
  onClickNews: () => void;
  onClickPhilosophy: () => void;
  onClickProjects: () => void;
  onClickTeam: () => void;
}

interface State {
  open?: boolean;
}

export default class Menu extends React.Component<Props, State> {
  state: State = {};

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleToggle = (e: React.SyntheticEvent<HTMLElement>) => {
    e.currentTarget.blur();
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const {
      onClickContact,
      onClickNews,
      onClickPhilosophy,
      onClickProjects,
      onClickTeam
    } = this.props;

    const { open } = this.state;

    return (
      <div
        className="menu"
        onClick={this.handleToggle}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
      >
        <button className="menu__icon" />
        <ul className={`menu__links ${open ? "menu__links--open" : ""}`}>
          <li onClick={onClickNews}>Actualités</li>
          <li onClick={onClickProjects}>Projets</li>
          <li onClick={onClickPhilosophy}>Philosophie</li>
          <li onClick={onClickTeam}>Equipe</li>
          <li onClick={onClickContact}>Contact</li>
        </ul>
      </div>
    );
  }
}
