import React from "react";
import GalleryLine from "./GalleryLine";
import { IGallery } from "../types";
import "./Gallery.css";

interface Props {
  lines: IGallery[];
}

export default function Gallery({ lines }: Props) {
  return (
    <div className="gallery">
      {lines.map(line => (
        <GalleryLine key={line.id} line={line} />
      ))}
    </div>
  );
}
