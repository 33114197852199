import React from "react";
import { IImage } from "../types";

export default function Thumbnail({
  className,
  image,
  onClick
}: {
  className?: string;
  image: IImage;
  onClick?: () => void;
}) {
  const { alt = "", path, derivatives } = image;
  let src = path;

  if (derivatives && derivatives.small) {
    src = derivatives.small;
  }

  return <img alt={alt} className={className} onClick={onClick} src={src} />;
}
