import React from "react";
import { getProjects } from "../api/projects";
import { IProject, ITaxonomyTerm } from "../types";
import Categories from "./Categories";
import Loader from "./Loader";
import "./Projects.css";
import Thumbnail from "./Thumbnail";

interface Props {
  category?: ITaxonomyTerm;
  onProjectClick: (uuid: string) => void;
}

interface State {
  category?: ITaxonomyTerm;
  loading?: boolean;
  projects?: IProject[];
}

export default class Projects extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      category: props.category
    };
  }

  componentDidMount() {
    this.fetchProjects();
  }

  componentDidUpdate(prevProps: Props) {
    const { category } = this.props;
    if (prevProps.category !== category) {
      this.setState({ category }, this.fetchProjects);
    }
  }

  handleClick = (category?: ITaxonomyTerm) => {
    this.setState({ category }, this.fetchProjects);
  };

  fetchProjects = () => {
    const { category } = this.state;

    this.setState({ loading: true });
    getProjects(category && category.tid).then(projects => {
      this.setState({ loading: false, projects });
    });
  };

  render() {
    const { category, loading, projects } = this.state;

    return (
      <div className="projects container">
        <div className="projects__header">
          <h2 className="page-title">
            <span className="page-title__first-line">Tous nos</span>
            <span className="page-title__second-line">Projets</span>
          </h2>
        </div>

        <Categories
          activeCategory={category}
          onCategoryClick={this.handleClick}
        />

        {loading && <Loader />}

        {projects !== undefined && !loading && (
          <div className="projects__wrapper">
            {projects.map(project => (
              <article
                className="projects__project"
                key={project.id}
                onClick={() => {
                  this.props.onProjectClick(project.id);
                }}
              >
                <Thumbnail image={project.mainImage} />
                <h1 className="projects__project-title">{project.title}</h1>
              </article>
            ))}
          </div>
        )}
      </div>
    );
  }
}
