import axios from "axios";
import { IHomePage } from "../types";
import { BASE_URL, CONSUMER_UUID, getIncludedImages } from "./helpers";

const FIELDS = [
  "nid",
  "changed",
  "field_philosophy",
  "field_associate_1_bio",
  "field_associate_1_dipl",
  "field_associate_1_name",
  "field_associate_1_photo",
  "field_associate_2_bio",
  "field_associate_2_dipl",
  "field_associate_2_name",
  "field_associate_2_photo",
  "field_collaborators_txt",
  "field_collaborators_img",
  "field_diaporama"
];
const INCLUDES = [
  "field_collaborators_img",
  "field_associate_2_photo",
  "field_associate_1_photo",
  "field_diaporama"
];

export function getHomeContent(): Promise<IHomePage> {
  const params: any = {
    consumerId: CONSUMER_UUID,
    "fields[node--home_page]": FIELDS.join(","),
    include: INCLUDES.join(","),
    "page[limit]": 1
  };

  return axios
    .get(`${BASE_URL}/node/home_page`, {
      params
    })
    .then(({ data: payload }) => {
      return convertJsonApiPayload(payload.data[0], payload);
    });
}

function convertJsonApiPayload(item: any, payload: any): IHomePage {
  return {
    associates: [
      {
        bio: item.attributes.field_associate_1_bio.processed,
        diplomas: item.attributes.field_associate_1_dipl,
        name: item.attributes.field_associate_1_name,
        picture: getIncludedImages(
          payload,
          item,
          "field_associate_1_photo",
          true
        )[0]
      },
      {
        bio: item.attributes.field_associate_2_bio.processed,
        diplomas: item.attributes.field_associate_2_dipl,
        name: item.attributes.field_associate_2_name,
        picture: getIncludedImages(
          payload,
          item,
          "field_associate_2_photo",
          true
        )[0]
      }
    ],
    collaborators: {
      names: item.attributes.field_collaborators_txt,
      picture: getIncludedImages(
        payload,
        item,
        "field_collaborators_img",
        true
      )[0]
    },
    diaporama: getIncludedImages(payload, item, "field_diaporama", true),
    philosophy: item.attributes.field_philosophy.processed
  };
}
