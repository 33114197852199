import {
  Dict,
  IDownloadableFile,
  IImage,
  IImageDerivatives,
  IReferencedValue,
  ITaxonomyTerm
} from "../types";

export const BASE_URL = "/cms/jsonapi";

export const CONSUMER_UUID = "e45a13d0-6721-4388-891e-4bc8104917d4";

interface IncludedData {
  attributes: {
    drupal_internal__fid?: number;
    drupal_internal__tid?: number;
    filemime?: string;
    name?: string;
    uri?: {
      url: string;
    };
  };
  id: string;
  type: string;
}

interface EnhancedReferencedValue extends IReferencedValue {
  fid?: number;
  filemime?: string;
  meta?: {
    alt: string;
    imageDerivatives?: { links: Dict<{ href: string }> };
  };
  path?: string;
  tid?: number;
  name?: string;
}

export function getIncludedValues(
  payload: any,
  dataItem: any | undefined,
  fieldName: string,
  forceType?: string
) {
  // TODO: memoize??
  if (
    dataItem &&
    dataItem.relationships &&
    dataItem.relationships[fieldName] &&
    dataItem.relationships[fieldName].data &&
    payload.included &&
    payload.included.length
  ) {
    let relationships: IReferencedValue[];

    // Single or multiple property? For convenience, we convert all properties
    // to arrays.
    if (dataItem.relationships[fieldName].data.length === undefined) {
      // Not an array, so it must be a single property. Convert to an array.
      relationships = [
        dataItem.relationships[fieldName].data as IReferencedValue
      ];
    } else {
      // An array, use as-is.
      relationships = dataItem.relationships[fieldName]
        .data as IReferencedValue[];
    }

    const values: EnhancedReferencedValue[] = [];
    relationships.forEach(relationship => {
      let id: string;
      let type: string;
      if (forceType !== undefined && relationship.target_uuid) {
        id = relationship.target_uuid;
        type = forceType;
      } else {
        id = relationship.id || "";
        type = relationship.type;
      }

      const included = (payload.included as IncludedData[]).find(item => {
        return item.id === id && item.type === type;
      });

      if (included) {
        values.push({
          fid: included.attributes.drupal_internal__fid,
          filemime: included.attributes.filemime,
          id,
          meta: (relationship as any).meta,
          name: included.attributes.name,
          path: included.attributes.uri && included.attributes.uri.url,
          tid: included.attributes.drupal_internal__tid,
          type
        });
      }
    });

    return values;
  }

  return [];
}

export function getIncludedTaxonomyTerms(
  payload: any,
  item: any,
  fieldName: string
): ITaxonomyTerm[] {
  const values: ITaxonomyTerm[] = [];
  getIncludedValues(payload, item, fieldName).forEach(element => {
    if (isTaxonomyTerm(element)) {
      values.push(element);
    }
  });
  return values;
}

export function getIncludedImages(
  payload: any,
  item: any,
  fieldName: string,
  hasDerivatives?: boolean
): IImage[] {
  const values: IImage[] = [];
  getIncludedValues(
    payload,
    item,
    fieldName,
    hasDerivatives ? "file--file" : undefined
  ).forEach(element => {
    if (isImage(element)) {
      const { meta } = element as EnhancedReferencedValue;
      const derivatives: IImageDerivatives = {};

      if (meta && meta.imageDerivatives) {
        Object.keys(meta.imageDerivatives.links).forEach(key => {
          const match = key.match(/^(large|medium|small)/g);
          if (match && match.length) {
            const size = match.pop() as keyof IImageDerivatives;
            derivatives[size] = (meta.imageDerivatives as any).links[key].href;
          }
        });
      }

      values.push({
        alt: meta && meta.alt,
        derivatives,
        fid: element.fid,
        id: element.id,
        path: element.path,
        type: element.type
      });
    }
  });
  return values;
}

export function getIncludedFiles(
  payload: any,
  item: any,
  fieldName: string
): IImage[] {
  const values: IImage[] = [];
  getIncludedValues(payload, item, fieldName).forEach(element => {
    if (isFile(element)) {
      values.push(element);
    }
  });
  return values;
}

function isTaxonomyTerm(
  element: EnhancedReferencedValue
): element is ITaxonomyTerm {
  return element.tid !== undefined && element.name !== undefined;
}

function isImage(element: EnhancedReferencedValue): element is IImage {
  return element.fid !== undefined && element.path !== undefined;
}

function isFile(
  element: EnhancedReferencedValue
): element is IDownloadableFile {
  return element.fid !== undefined && element.path !== undefined;
}
