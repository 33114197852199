import React from "react";
import { getNews } from "../api/news";
import { INews } from "../types";
import AsyncResponsiveImage from "./AsyncResponsiveImage";
import Loader from "./Loader";
import "./News.css";

interface State {
  loading?: boolean;
  news?: INews[];
}

export default class News extends React.Component<{}, State> {
  state: State = {};

  componentDidMount() {
    this.fetchNews();
  }

  fetchNews = () => {
    this.setState({ loading: true });
    getNews().then(result => {
      this.setState({ loading: false, news: result });
    });
  };

  render() {
    const { loading, news } = this.state;

    if (!news) {
      return loading ? <Loader /> : null;
    }

    return (
      <div className="news">
        <div className="container">
          <div className="news__header">
            <h2 className="page-title">
              <span className="page-title__first-line">Nos dernières</span>
              <span className="page-title__second-line">Actualités</span>
            </h2>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="news__wrapper">
              {news.map(newsItem => (
                <article
                  className="news__news-item news-item"
                  key={newsItem.id}
                >
                  <div className="news-item__left">
                    {newsItem.mainImage !== undefined && (
                      <AsyncResponsiveImage
                        className={`news-item__image news-item__image-${newsItem.mainImage.type.replace(
                          "paragraph--",
                          ""
                        )}`}
                        image={newsItem.mainImage}
                      />
                    )}
                  </div>
                  <div className="news-item__right">
                    <time className="news-item__date">
                      {new Intl.DateTimeFormat("fr-FR", {
                        day: "numeric",
                        month: "long",
                        year: "2-digit"
                      }).format(newsItem.date)}
                    </time>
                    <h1 className="news-item__title">{newsItem.title}</h1>
                    <div
                      className="news-item__description"
                      dangerouslySetInnerHTML={{
                        __html: newsItem.teaser
                      }}
                    />
                  </div>
                </article>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
