import React from "react";
import "./Loader.css";

export default function() {
  return (
    <div className="loader">
      <img src="/images/loader.gif" alt="" />
    </div>
  );
}
