import React from "react";
import { IHomePage } from "../types";
import ResponsiveImage from "./ResponsiveImage";
import "./Team.css";

export default function Team({
  content,
  loading
}: {
  content?: IHomePage;
  loading?: boolean;
}) {
  if (loading || !content) {
    // We already show a loader.
    return null;
  }

  let rest = [...content.collaborators.names];
  let slices = [];
  while (rest.length) {
    slices.push(rest.splice(0, 7));
  }

  return (
    <div className="team">
      <div className="team__header container">
        <h2 className="page-title">
          <span className="page-title__first-line">Notre</span>
          <span className="page-title__second-line">Equipe</span>
        </h2>
      </div>
      <div className="team__associates container">
        {content.associates.map((associate, i) => (
          <div key={i} className="team-associate">
            <div className="team-associate__left">
              <ResponsiveImage image={associate.picture} />
            </div>
            <div className="team-associate__right">
              <div className="team-associate__name">{associate.name}</div>
              <div className="team-associate__diplomas">
                {associate.diplomas}
              </div>
              <div
                className="team-associate__bio"
                dangerouslySetInnerHTML={{
                  __html: associate.bio
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="team__collaborators">
        <div className="team__collaborators-photo">
          <ResponsiveImage image={content.collaborators.picture} />
        </div>
        <div className="container">
          {slices.map((slice, i) => (
            <ul key={i}>
              {slice.map(name => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
}
