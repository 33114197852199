import axios from "axios";
import { INews } from "../types";
import { BASE_URL, CONSUMER_UUID } from "./helpers";

const FIELDS = [
  "nid",
  "changed",
  "title",
  "field_date",
  "field_image_news",
  "body"
];
const INCLUDES = ["field_image_news"];

export function getNews(): Promise<INews[]> {
  const params = {
    consumerId: CONSUMER_UUID,
    "fields[node--news]": FIELDS.join(","),
    include: INCLUDES.join(","),
    "page[limit]": 3,
    sort: "-field_date"
  };

  return axios
    .get(`${BASE_URL}/node/news`, {
      params
    })
    .then(({ data: payload }) => {
      return payload.data.map((item: any, i: number) => {
        return convertJsonApiPayload(item, payload);
      });
    });
}

function convertJsonApiPayload(item: any, payload: any): INews {
  return {
    date: new Date(item.attributes.field_date),
    id: item.id,
    mainImage: item.relationships.field_image_news.data,
    teaser:
      item.attributes.body.summary !== undefined &&
      (item.attributes.body.summary as string).length > 0
        ? item.attributes.body.summary
        : item.attributes.body.processed,
    title: item.attributes.title
  };
}
