import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import "./Contact.css";
import Copyright from "./Copyright";
import icon from "./gmaps-marker.svg";
import MAP_STYLES from "./GMapStyles.json";
import Loader from "./Loader";

export default function Contact() {
  return (
    <div className="contact">
      <div className="contact__header container">
        <h2 className="page-title">
          <span className="page-title__first-line">Nous</span>
          <span className="page-title__second-line">Contacter</span>
        </h2>
      </div>
      <Map
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBVCKtITX0oyqGsL16WXrTVnFdG0_qqi10&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<Loader />}
        containerElement={<div className="contact__map" />}
        mapElement={
          <div className="contact__map-element" style={{ height: "100%" }} />
        }
      />
      <div className="contact__info container">
        <p>
          <img
            alt="VVR Architectes"
            className="contact__logo"
            src="/images/logo.svg"
          />
          <br />
          Rue de Genève 122
          <br />
          CP 248 1226 Thônex
          <br />
          <a className="contact__phone" href="tel:+41228608620">
            t +41 22 860 86 20
          </a>
          <br />
          <a className="contact__email" href="mailto:info@vvr.ch">
            info@vvr.ch
          </a>
        </p>
      </div>
      <Copyright />
    </div>
  );
}

const Map = withScriptjs(
  withGoogleMap(() => {
    return (
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: 46.192746, lng: 6.202972 }}
        defaultOptions={{
          disableDefaultUI: true,
          styles: MAP_STYLES
        }}
      >
        <Marker
          icon={icon}
          onClick={() => {
            window.location.href = "https://goo.gl/maps/D2FjMvp4eiXGWFyW6";
          }}
          position={{ lat: 46.192664, lng: 6.202993 }}
        />
      </GoogleMap>
    );
  })
);
